<template>
  <v-dialog
    v-model="deleteWhitelistVisible"
    width="400"
    persistent
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="deleteWhitelistDisabled"
        text
        v-bind="attrs"
        v-on="on"
        class="btn-s ml-2"
        >{{ $t('Delete') }}</v-btn
      >
    </template>

    <v-card class="popup add_store_popup">
      <v-card-title>
      <h3 class="page-title-bar">
        <i class="ico ico-store"></i>{{ $t('Delete Whitelist') }}
      </h3>
    </v-card-title>
    <v-card-text >
      <v-row>
        <v-col>
          <p class="label-txt">{{ $t('Delete Type') }}</p>
          <v-radio-group v-model="addType" dense hide-details row>
            <v-radio
              value="typing"
              :label="$t('Typing')"
              class="form-radio flat"
            ></v-radio>
            <v-radio
              value="fileUpload"
              :label="$t('File Upload')"
              class="form-radio flat"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <!-- typing 선택일 경우 노출 -->
      <v-row v-if="addType === 'typing'">
        <v-col>
          <v-text-field
            v-model="typedLabelId"
            outlined
            dense
            hide-details
            :label="`${$t('Label ID')} *`"
            :placeholder="$t('Input the label ID')"
            class="form-input"
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- file upload 선택일 경우 노출 -->
      <v-row v-else>
        <v-col>
          <v-file-input
            v-model="uploadedLabelIds"
            outlined
            dense
            hide-details
            prepend-icon
            flat
            solo
            placeholder="Select file"
            class="form-file"
          >
            <template v-slot:append>
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>
          <div style="margin-top:9px;font-weight:600;color:red;margin-bottom: -30px;width: 100%;">
        <p> * {{$t('When initiating a large-scale data upload, you may encounter a warning window.')}}<br>
       </p>
      </div>
        </v-col>
      </v-row>
</v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn @click="deleteWhitelist" text icon class="btn">{{
          $t('Delete')
        }}</v-btn>
        <v-btn text icon @click="cancelDeleteWhitelist" class="btn">{{
          $t('Cancel')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/plugins/eventBus.js'

const inputType = {
  typing: 'typing',
  fileUpload: 'fileUpload'
}

export default {
  props: ['deleteWhitelistDisabled'],
  data () {
    return {
      deleteWhitelistVisible: false,
      addType: inputType.typing,
      typedLabelId: null,
      uploadedLabelIds: null
    }
  },
  methods: {
    cancelDeleteWhitelist () {
      this.deleteWhitelistVisible = false
      this.uploadedLabelIds = null
      this.typedLabelId = null
      this.addType = inputType.typing
    },
    isValid (val) {
      if (val !== null && val !== undefined) return true
      return false
    },
    isValidStr (val) {
      if (val !== null && val !== undefined && val.trim().length > 0) { return true }
      return false
    },
    deleteWhitelist () {
      let labels = []
      if (this.addType === inputType.typing) {
        if (!this.isValidStr(this.typedLabelId)) {
          EventBus.$emit('messageAlert', this.$t('Input the label ID.'))
          return
        }
        labels.push(this.typedLabelId)
        this.$emit('deleteWhitelist', labels)
      } else {
        if (!this.isValid(this.uploadedLabelIds)) {
          EventBus.$emit('messageAlert', this.$t('Input the label ID.'))
          return
        }
        const fr = new FileReader()
        fr.readAsText(this.uploadedLabelIds)
        fr.onload = () => {
          labels = fr.result
            .split('\n')
            .filter(label => this.isValidStr(label))
            .map(label => label.trim())
          this.cancelDeleteWhitelist()

          this.$emit('deleteWhitelist', labels)
        }
      }
    }
  },
  mounted () {
    EventBus.$on('fireCancelDeleteWhitelist', () => {
      this.cancelDeleteWhitelist()
    })
  }
}
</script>

<style></style>
