var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "400", persistent: "", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn-s ml-2",
                      attrs: { disabled: _vm.deleteWhitelistDisabled, text: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("Delete")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.deleteWhitelistVisible,
        callback: function($$v) {
          _vm.deleteWhitelistVisible = $$v
        },
        expression: "deleteWhitelistVisible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-store" }),
              _vm._v(_vm._s(_vm.$t("Delete Whitelist")) + " ")
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("p", { staticClass: "label-txt" }, [
                        _vm._v(_vm._s(_vm.$t("Delete Type")))
                      ]),
                      _c(
                        "v-radio-group",
                        {
                          attrs: { dense: "", "hide-details": "", row: "" },
                          model: {
                            value: _vm.addType,
                            callback: function($$v) {
                              _vm.addType = $$v
                            },
                            expression: "addType"
                          }
                        },
                        [
                          _c("v-radio", {
                            staticClass: "form-radio flat",
                            attrs: { value: "typing", label: _vm.$t("Typing") }
                          }),
                          _c("v-radio", {
                            staticClass: "form-radio flat",
                            attrs: {
                              value: "fileUpload",
                              label: _vm.$t("File Upload")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.addType === "typing"
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "form-input",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("Label ID") + " *",
                              placeholder: _vm.$t("Input the label ID"),
                              clearable: ""
                            },
                            model: {
                              value: _vm.typedLabelId,
                              callback: function($$v) {
                                _vm.typedLabelId = $$v
                              },
                              expression: "typedLabelId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-file-input", {
                            staticClass: "form-file",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              "prepend-icon": "",
                              flat: "",
                              solo: "",
                              placeholder: "Select file"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function() {
                                  return [
                                    _c("img", {
                                      attrs: {
                                        width: "24",
                                        height: "24",
                                        src: require("@/assets/img/ico-upload-file.gif")
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.uploadedLabelIds,
                              callback: function($$v) {
                                _vm.uploadedLabelIds = $$v
                              },
                              expression: "uploadedLabelIds"
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "9px",
                                "font-weight": "600",
                                color: "red",
                                "margin-bottom": "-30px",
                                width: "100%"
                              }
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  " * " +
                                    _vm._s(
                                      _vm.$t(
                                        "When initiating a large-scale data upload, you may encounter a warning window."
                                      )
                                    )
                                ),
                                _c("br")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.deleteWhitelist }
                },
                [_vm._v(_vm._s(_vm.$t("Delete")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.cancelDeleteWhitelist }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }