<template>
  <v-dialog
    v-model="addWhitelistVisible"
    width="400"
    persistent
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="addWhitelistDisabled"
        text
        v-bind="attrs"
        v-on="on"
        class="btn-s"
        >{{ $t('Add') }}</v-btn
      >
    </template>

    <v-card class="popup add_store_popup">
      <v-card-title>
      <h3 class="page-title-bar">
        <i class="ico ico-store"></i>{{ $t('Add Whitelist') }}
      </h3>
    </v-card-title>
    <v-card-text >
      <v-row>
        <v-col>
          <p class="label-txt">{{ $t('Add') }}</p>
          <v-radio-group v-model="addType" dense hide-details row>
            <v-radio
              value="typing"
              :label="$t('By label ID')"
              class="form-radio flat"
            ></v-radio>
            <v-radio
              value="fileUpload"
              :label="$t('By file')"
              class="form-radio flat"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="addType === 'typing'">
        <v-col>
          <v-text-field
            v-model="typedLabelId"
            outlined
            dense
            hide-details
            :label="`${$t('Label ID')} *`"
            placeholder="Input label ID"
            class="form-input"
            clearable
            @keydown="clearTabindex"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-file-input
            v-model="uploadedLabelIds"
            outlined
            dense
            hide-details
            prepend-icon
            flat
            solo
            placeholder="Select file"
            class="form-file"
            @keydown="clearTabindex"
          >
            <template v-slot:append>
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>
          <div style="margin-top:9px;font-weight:600;color:red;margin-bottom: -30px;width: 100%;">
        <p> * {{$t('When initiating a large-scale data upload, you may encounter a warning window.')}}<br>
       </p>
      </div>
        </v-col>
      </v-row>
</v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn @click="addWhitelist" text icon class="btn">{{
          $t('Add')
        }}</v-btn>
        <v-btn text icon @click="cancelAddWhitelist" class="btn">{{
          $t('Cancel')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/plugins/eventBus.js'

const inputType = {
  typing: 'typing',
  fileUpload: 'fileUpload'
}

export default {
  props: ['addWhitelistDisabled'],
  data () {
    return {
      addWhitelistVisible: false,
      addType: inputType.typing,
      typedLabelId: null,
      uploadedLabelIds: null
    }
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    cancelAddWhitelist () {
      this.addWhitelistVisible = false
      this.uploadedLabelIds = null
      this.typedLabelId = null
      this.addType = inputType.typing
    },
    isValid (val) {
      if (val !== null && val !== undefined) return true
      return false
    },
    isValidStr (val) {
      if (val !== null && val !== undefined && val.trim().length > 0) { return true }
      return false
    },
    addWhitelist () {
      let labels = []
      if (this.addType === inputType.typing) {
        if (!this.isValidStr(this.typedLabelId)) {
          EventBus.$emit('messageAlert', this.$t('Input label ID.'))
          return
        }
        labels.push(this.typedLabelId)
        this.$emit('addWhitelist', labels)
      } else {
        if (!this.isValid(this.uploadedLabelIds)) {
          EventBus.$emit('messageAlert', this.$t('Input label ID.'))
          return
        }
        const fr = new FileReader()
        fr.readAsText(this.uploadedLabelIds)
        fr.onload = () => {
          labels = fr.result
            .split('\n')
            .filter(label => this.isValidStr(label))
            .map(label => label.trim())
          this.cancelAddWhitelist()
          this.$emit('addWhitelist', labels)
        }
      }
    }
  },
  mounted () {
    EventBus.$on('fireCancelAddWhitelist', () => {
      this.cancelAddWhitelist()
    })
  }
}
</script>

<style scoped>
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
